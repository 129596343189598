@font-face {
  font-family: HalvarMittel;
  src: url("../assets/font/HalvarMittel-Md.ttf");
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: HalvarMittel;
  src: url("../assets/font/HalvarMittel-Bd.ttf");
  font-weight: 700;
  font-display: fallback;
}

@font-face {
  font-family: HalvarMittel;
  src: url("../assets/font/HalvarMittel-Rg.ttf");
  font-weight: normal;
  font-display: fallback;
}
@font-face {
  font-family: HalvarMittel;
  src: url("../assets/font/HalvarMittel-RgSuSlanted.ttf");
  font-weight: normal;
  font-style: oblique;
  font-display: fallback;
}

@font-face {
  font-family: HalvarMittel;
  src: url("../assets/font/HalvarMittel-Th.ttf");
  font-weight: 300;
  font-display: fallback;
}