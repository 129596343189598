@charset "UTF-8";
@font-face {
  font-family: HalvarMittel;
  src: url("../assets/font/HalvarMittel-Md.ttf");
  font-weight: 500;
  font-display: fallback;
}
@font-face {
  font-family: HalvarMittel;
  src: url("../assets/font/HalvarMittel-Bd.ttf");
  font-weight: 700;
  font-display: fallback;
}
@font-face {
  font-family: HalvarMittel;
  src: url("../assets/font/HalvarMittel-Rg.ttf");
  font-weight: normal;
  font-display: fallback;
}
@font-face {
  font-family: HalvarMittel;
  src: url("../assets/font/HalvarMittel-RgSuSlanted.ttf");
  font-weight: normal;
  font-style: oblique;
  font-display: fallback;
}
@font-face {
  font-family: HalvarMittel;
  src: url("../assets/font/HalvarMittel-Th.ttf");
  font-weight: 300;
  font-display: fallback;
}
* {
  color: #FFFFFF;
}

body {
  font-family: "HalvarMittel", "Calibri", sans-serif;
}

.heading__primary {
  text-transform: uppercase;
  margin-top: 65vh;
}
@media (max-width: 1399px) {
  .heading__primary {
    margin-top: 50vh;
  }
}
@media (max-width: 649px) {
  .heading__primary {
    margin-top: 70vh;
  }
}
.heading__primary .mask {
  position: relative;
  overflow: hidden;
  height: 14rem;
}
.heading__primary .mask:first-of-type {
  height: 5rem;
}
@media (max-width: 849px) {
  .heading__primary .mask {
    height: 8rem;
  }
}
.heading__primary--top {
  font-size: 5.6rem;
  line-height: 1;
  font-weight: 300;
}
@media (max-width: 849px) {
  .heading__primary--top {
    font-size: 4.6rem;
  }
}
@media (max-width: 649px) {
  .heading__primary--top {
    font-size: 3.6rem;
  }
}
.heading__primary--bottom {
  font-size: 14rem;
  line-height: 1;
  font-weight: 500;
}
@media (max-width: 849px) {
  .heading__primary--bottom {
    font-size: 9rem;
  }
}
@media (max-width: 649px) {
  .heading__primary--bottom {
    font-size: 7rem;
  }
}
.heading__primary span {
  display: block;
}

.heading__secondary {
  font-size: 2.6rem;
  font-weight: 300;
  margin-bottom: 2.6rem;
  line-height: 31px;
  text-transform: uppercase;
}

.reveal-text {
  position: absolute;
  z-index: 200;
  top: 100%;
}

p.lead {
  margin-bottom: 2.5rem;
  font-size: 3.6rem;
  font-weight: 700;
}
@media (max-width: 849px) {
  p.lead {
    font-size: 3rem;
  }
}
@media (max-width: 649px) {
  p.lead {
    font-size: 2.5rem;
  }
}
p.lead i {
  font-weight: normal;
  font-style: oblique;
}
p:not(.lead) {
  font-size: 1.8rem;
  font-weight: normal;
}

.about p:not(.lead) {
  padding-left: 4.5rem;
}
.about p:not(.lead):not(:last-child) {
  margin-bottom: 2.5rem;
}

input {
  font-family: "Montserrat", sans-serif;
}

.text-box {
  position: relative;
}
.text-box h3 {
  font-size: 3rem;
  font-weight: 600;
}
.text-box p {
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.text-box > :not(:last-child) {
  margin-bottom: 2rem;
}

.numbers .text-box h3 {
  font-weight: 500;
  font-size: 10rem;
}
@media (max-width: 849px) {
  .numbers .text-box h3 {
    font-size: 6rem;
  }
}
.numbers .text-box h3 small {
  font-size: 6rem;
}
@media (max-width: 849px) {
  .numbers .text-box h3 small {
    font-size: 3rem;
  }
}

.contact h4 {
  font-size: 3.6rem;
  margin: 3rem 0;
  font-style: normal;
}
.contact p {
  font-size: 2rem;
  font-style: normal;
}
.contact a {
  display: block;
  font-size: 3rem;
  text-decoration: none;
}
.contact address p {
  font-size: 1.4rem;
}
.contact address a {
  font-size: inherit;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-x: hidden;
}
@media (max-width: 1799px) {
  html {
    font-size: 57.5%;
  }
}
@media (max-width: 849px) {
  html {
    font-size: 55%;
  }
}

body {
  position: relative;
  background: #000000;
}
.fullscreen-bg {
  position: fixed;
  height: 100vh;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
@media (max-width: 849px) {
  .fullscreen-bg {
    height: 90vh;
  }
}
.fullscreen-bg .background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media (max-width: 849px) {
  .fullscreen-bg .background {
    -webkit-transform: translate(-50%, -55%);
    -moz-transform: translate(-50%, -55%);
    -ms-transform: translate(-50%, -55%);
    transform: translate(-50%, -55%);
  }
}
.fullscreen-bg .background--loop {
  z-index: -2;
}
@media (max-width: 849px) {
  .fullscreen-bg .background {
    height: auto;
    width: 100%;
  }
}

#map {
  height: 100%;
  min-height: 50vh;
}

.grid-layout {
  display: grid;
  grid-gap: 0 16px;
  grid-template-columns: 3rem [full-start] minmax(3rem, 1fr) [container-start] 10.2rem [central-start] 10.2rem [inside-container-start] repeat(4, 10.2rem) [center] repeat(4, 10.2rem) [inside-container-end] 10.2rem [central-end] 10.2rem [container-end] minmax(3rem, 1fr) [full-end] 3rem;
}
@media (max-width: 1399px) {
  .grid-layout {
    grid-template-columns: 1.5rem [full-start] minmax(1.5rem, 1fr) [container-start] repeat(2, 5rem) [inside-container-start] repeat(4, 10.2rem) [center] repeat(4, 10.2rem) [inside-container-end] repeat(2, 5rem) [container-end] minmax(1.5rem, 1fr) [full-end] 1.5rem;
  }
}
@media (max-width: 849px) {
  .grid-layout {
    grid-template-columns: 1.5rem [full-start] minmax(1.5rem, 1fr) [container-start] 1fr [center] 1fr [container-end] minmax(1.5rem, 1fr) [full-end] 1.5rem;
  }
}

header {
  height: calc(100vh - 5rem);
  grid-column: container-start/container-end;
}
@media (max-width: 849px) {
  header {
    grid-column: full-start/full-end;
  }
}

.about {
  grid-column: container-start/inside-container-end;
  display: flex;
  position: relative;
}
.about:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  filter: blur(20px);
  z-index: -1;
  opacity: 0;
  transition: opacity 300ms ease;
}
.about.active::after {
  opacity: 1;
}
@media (max-width: 849px) {
  .about {
    grid-column: full-start/full-end;
    margin-top: 15rem;
  }
}
.about p:not(.lead) {
  max-width: 50%;
}
@media (max-width: 999px) {
  .about p:not(.lead) {
    max-width: unset;
  }
}

.feature-movie {
  grid-column: central-start/central-end;
  height: 300vh;
  position: relative;
}
.feature-movie.safari {
  height: 125vh;
}
@media (max-width: 849px) {
  .feature-movie.safari {
    height: auto;
  }
}
@media (max-width: 1399px) {
  .feature-movie {
    grid-column: 5/span 8;
  }
}
@media (max-width: 849px) {
  .feature-movie {
    min-height: 33vh;
    grid-column: full-start/full-end;
    height: auto;
  }
}
.feature-movie__content {
  top: 50%;
  margin-top: 50%;
  transform: translateY(-50%);
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
}
@media (max-width: 849px) {
  .feature-movie__content {
    margin-top: 0;
    top: auto;
    transform: translateY(0);
    position: relative;
    height: 80vh;
    object-fit: cover;
  }
}
.feature-movie__content:-webkit-full-screen {
  position: static !important;
  transform: none !important;
  margin-top: 0 !important;
  cursor: default !important;
}

.selected {
  grid-column: 1/-1;
  position: relative;
}
@media (max-width: 849px) {
  .selected {
    grid-column: full-start/-1;
    overflow: hidden;
  }
}
.selected .swiper {
  overflow: unset;
}
.selected .swiper .swiper-slide-active .selected__item--title {
  opacity: 1;
  transition: opacity 300ms ease;
}
.selected .swiper .swiper-slide-active .selected__item--info {
  opacity: 1;
  transition: opacity 300ms ease;
}
.selected .swiper .swiper-slide-active .selected__item--more-info {
  opacity: 1;
  transition: opacity 300ms ease;
}
.selected__title {
  position: absolute;
}
.selected__title h2 {
  position: sticky;
  position: -webkit-sticky;
}
.selected__container {
  grid-column: container-start/-1;
  display: flex;
  padding-top: 10vh;
}
@media (max-width: 849px) {
  .selected__container {
    grid-column: 1/-1;
  }
}
.selected__item {
  height: 65vh;
  min-height: 600px;
  margin-top: 10vh;
  margin-bottom: 10vh;
  display: grid;
  grid-template-columns: 20% 20% auto;
  grid-template-rows: 50vh auto;
  grid-gap: 5rem;
}
@media (max-width: 849px) {
  .selected__item {
    grid-template-rows: 40vh 2rem auto;
    grid-gap: 1rem;
    height: 65vh;
    margin-top: 10vh;
    min-height: unset;
  }
}
@media (max-width: 649px) {
  .selected__item {
    grid-template-rows: 15vh 25vh 25vh;
    grid-template-columns: 40% 40% 20%;
  }
}
@media (max-width: 376px) {
  .selected__item {
    grid-template-rows: 10vh 20vh 40vh;
  }
}
.selected__item p {
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 18px;
}
.selected__item--wrapper {
  grid-column: 1/-1;
  max-height: 50vh;
  margin-right: 5vw;
  position: relative;
}
@media (max-width: 649px) {
  .selected__item--wrapper {
    grid-column: 2/-1;
    grid-row: 1/-1;
    max-height: unset;
  }
}
.selected__item--title {
  grid-column: 1;
  transition: opacity 300ms ease;
  opacity: 0;
  font-size: 1.9rem;
  font-weight: 700;
}
@media (max-width: 849px) {
  .selected__item--title {
    grid-column: 1/-1;
    opacity: 1;
  }
}
@media (max-width: 649px) {
  .selected__item--title {
    grid-column: 1;
    grid-row: 1;
    font-size: 1.6rem;
  }
}
.selected__item--info {
  grid-column: 2;
  transition: opacity 300ms ease;
  opacity: 0;
}
@media (max-width: 849px) {
  .selected__item--info {
    grid-column: 1;
    opacity: 1;
  }
}
@media (max-width: 649px) {
  .selected__item--info {
    grid-column: 1;
    grid-row: 2;
  }
}
.selected__item--more-info {
  grid-column: 3;
  transition: opacity 300ms ease;
  opacity: 0;
}
@media (max-width: 849px) {
  .selected__item--more-info {
    grid-column: 2;
    opacity: 1;
  }
}
@media (max-width: 649px) {
  .selected__item--more-info {
    grid-column: 1;
    grid-row: 3;
  }
}
.selected__item--media {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
@media (max-width: 849px) {
  .selected__item--media {
    object-fit: cover;
  }
}
@media (max-width: 649px) {
  .selected__item--media {
    object-fit: cover;
    height: 95%;
  }
}
.selected__item--media:-webkit-full-screen {
  width: 100%;
  height: 100%;
}
.selected__item--play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #FFFFFF;
  opacity: 0.8;
}
@media (max-width: 849px) {
  .selected__item--play-btn {
    width: 50%;
    max-width: 75px;
  }
}
@media (max-width: 649px) {
  .selected__item--play-btn {
    max-width: 40px;
  }
}

.logo {
  stroke: #FFFFFF;
  fill: #FFFFFF;
}

.burger-icon {
  width: 100%;
  height: auto;
  max-height: 100%;
}

.fixed-bar {
  grid-column: full-start/full-end;
  display: flex;
  justify-content: space-between;
}

.social-icons {
  position: fixed;
  bottom: 4.5rem;
  display: flex;
  flex-direction: column;
  z-index: 100;
}
@media (max-width: 649px) {
  .social-icons {
    display: none;
  }
}
.social-icons__icon {
  display: block;
  height: 3rem;
  width: 3rem;
}
.social-icons__icon:not(:last-child) {
  margin-bottom: 2rem;
}
@media (max-width: 849px) {
  .social-icons__icon:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}
.social-icons__icon--media {
  fill: #FFFFFF;
  width: 100%;
  height: 100%;
  transition: all 300ms ease;
}
.social-icons__icon--media:hover {
  fill: #444;
}

.vertical {
  position: fixed;
  bottom: 4.5rem;
  right: 0;
  width: 30rem;
  z-index: 100;
  transform: rotate(90deg) translateX(-135px) translateY(-100px);
}
@media (max-width: 649px) {
  .vertical {
    display: none;
  }
}

.section-lead {
  grid-column: container-start/span 7;
  height: 60vh;
  padding-top: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 849px) {
  .section-lead {
    grid-column: full-start/full-end;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
}
@media (max-width: 649px) {
  .section-lead {
    padding-top: 0;
    height: auto;
    padding-bottom: 10rem;
  }
}

.services, .industries {
  grid-column: 1/-1;
  min-height: 100vh;
}

.talents, .facilities {
  padding-top: 20vh;
  grid-column: central-start/central-end;
}
@media (max-width: 1399px) {
  .talents, .facilities {
    grid-column: container-start/container-end;
  }
}
@media (max-width: 849px) {
  .talents, .facilities {
    grid-column: full-start/full-end;
  }
}
.talents__content, .facilities__content {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 4rem 11rem;
  min-height: 65rem;
}
@media (max-width: 849px) {
  .talents__content, .facilities__content {
    padding: 2rem 4rem;
    height: auto;
  }
}
.talents__content h2, .talents__content p, .facilities__content h2, .facilities__content p {
  z-index: 1;
}

.talents__content {
  background-image: url("../assets/img/ThePack_GroupPicture_1756_edit_v002_min.jpg");
  position: relative;
  background-position: bottom;
}
.talents__content::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0;
  transition: opacity 300ms ease;
}
.talents__content.active::after {
  opacity: 0.4;
}

.facilities {
  position: relative;
}
.facilities__mask {
  grid-column: 1/-1;
  overflow: hidden;
  padding-bottom: 30rem;
}
@media (max-width: 649px) {
  .facilities__mask {
    padding-bottom: 60rem;
  }
}
.facilities__text {
  position: absolute;
  top: 75%;
}
.facilities__text h2.heading__secondary {
  margin-bottom: 20rem;
}
.facilities .ticker-wrapper {
  position: absolute;
  bottom: 0;
}
.facilities__animated-text {
  display: flex;
  position: absolute;
  left: 0;
  z-index: 2;
  bottom: 60%;
  overflow: hidden;
  animation: ticker 180s linear infinite;
}
.facilities__animated-text p {
  width: 100%;
  white-space: nowrap;
  font-weight: 300;
  font-size: 5.6rem;
  margin-left: 10rem;
  position: relative;
}
@media (max-width: 1599px) {
  .facilities__animated-text p {
    font-size: 5rem;
  }
}
@media (max-width: 1399px) {
  .facilities__animated-text p {
    font-size: 4rem;
  }
}
@media (max-width: 849px) {
  .facilities__animated-text p {
    font-size: 2.5rem;
  }
}
.facilities__animated-text p:after {
  content: "⤑";
  position: absolute;
  height: 100%;
  right: -7.5rem;
  width: 5rem;
}
.facilities__content {
  position: relative;
  background-image: url("../assets/img/ThePack_lnt1_GradingRoom_1683_min.jpg");
}
@media (max-width: 849px) {
  .facilities__content {
    min-height: 20rem;
  }
}

.cta {
  height: 45vh;
  grid-column: container-start/span 8;
  padding-top: 20vh;
}
@media (max-width: 1399px) {
  .cta {
    min-height: 50rem;
  }
}
@media (max-width: 849px) {
  .cta {
    grid-column: full-start/full-end;
    padding-top: 5rem;
    height: auto;
  }
}

.contact {
  height: 45vh;
  grid-column: inside-container-start/inside-container-end;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 5vh;
}
@media (max-width: 849px) {
  .contact {
    grid-column: full-start/full-end;
  }
}
@media (max-width: 649px) {
  .contact {
    flex-direction: column;
    align-items: center;
    height: auto;
  }
}
.contact__box {
  width: 33%;
  max-width: 22rem;
  position: relative;
  margin-bottom: 5rem;
}
@media (max-width: 649px) {
  .contact__box {
    width: 100%;
    max-width: 300px;
    margin-top: 2.5rem;
    margin-bottom: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 50px auto;
  }
}
@media (max-width: 649px) {
  .contact__box h4 {
    grid-row: 1;
    grid-column: 1;
    margin: 0;
  }
}
@media (max-width: 649px) {
  .contact__box address {
    grid-row: 2;
    grid-column: 1;
  }
}
@media (max-width: 649px) {
  .contact__box button {
    grid-row: 3;
    grid-column: 1;
    align-items: center;
  }
}
.contact__btn {
  outline: none;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 649px) {
  .contact__btn {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.contact__btn span {
  font-size: 1.4rem;
}
.contact__icon {
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
  fill: #FFFFFF;
}

footer {
  grid-column: 1/-1;
}

.reveal {
  overflow: hidden;
  height: 100%;
  min-height: 250px;
}

.ios-iphone-bg {
  width: 100%;
  margin: auto;
  height: auto;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.lb {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.66);
  z-index: 1000;
}
.lb.ios {
  z-index: unset;
  background: none;
}
.lb__img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  height: auto;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 100%;
  grid-column: 1/-1;
  min-height: 20rem;
}

.swiper-slide {
  display: block;
  width: 80%;
}
@media (max-width: 649px) {
  .swiper-slide {
    width: 85%;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  width: 2rem;
  height: 2rem;
  background: rgba(255, 255, 255, 0.6);
}

.swiper-pagination-bullet-active {
  background: rgba(255, 255, 255, 0.8);
}

.anchor {
  display: block;
  height: 155px;
  margin-top: -155px;
  visibility: hidden;
}

.selected .swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: none;
}
.selected .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0;
}

.feature-btn-play {
  display: none;
}
@media (max-width: 649px) {
  .feature-btn-play {
    display: block;
    max-width: 100px;
  }
}

@keyframes cursorClick {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes ticker {
  0% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
    -ms-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }
}
.navigation {
  grid-column: full-start/full-end;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  height: 5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 100;
}
@media (max-width: 849px) {
  .navigation {
    position: fixed;
    width: 100%;
    height: 0;
    z-index: 1001;
  }
}
.navigation__logo {
  position: absolute;
  left: 0;
  top: 18px;
  height: 2.5rem;
  width: 2.5rem;
  opacity: 1;
  transition: opacity 900ms ease;
}
.navigation__logo.hide {
  opacity: 0;
}
@media (max-width: 849px) {
  .navigation__logo {
    left: 2.5rem;
    top: 2.5rem;
    z-index: 1;
  }
}
@media (max-width: 649px) {
  .navigation__logo {
    left: calc(1.5rem + 16px);
  }
}
.navigation__logo--link {
  position: relative;
  display: block;
}
.navigation__logo--link svg {
  height: 5rem;
  width: 3.5rem;
}
.navigation__wrapper {
  position: relative;
  overflow: hidden;
  max-width: 700px;
  width: 100%;
  height: 5rem;
}
@media (max-width: 849px) {
  .navigation__wrapper {
    overflow: unset;
    height: 100vh;
    background: #000000;
    transform: translateY(-100%);
  }
}
.navigation__list {
  min-width: 100%;
  position: absolute;
  height: 100%;
  width: 700px;
  right: 0;
  display: flex;
  align-items: center;
  list-style: none;
}
@media (max-width: 849px) {
  .navigation__list {
    background: #111;
    flex-direction: column;
    justify-content: space-around;
    width: auto;
    position: static;
    overflow: hidden;
    transform: translateY(-100%);
  }
}
.navigation__item {
  padding: 1rem;
}
@media (max-width: 849px) {
  .navigation__item {
    width: 100%;
    height: 12.5vh;
    text-align: center;
    border-bottom: 1px solid #333;
    padding: 0;
  }
}
.navigation__item:last-child {
  padding-right: 0;
}
.navigation__item--link {
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #FFFFFF;
  text-decoration: none;
  letter-spacing: 1px;
  white-space: nowrap;
  border-bottom: 2px solid transparent;
  transition: all 300ms ease;
  will-change: border;
}
@media (max-width: 849px) {
  .navigation__item--link {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.navigation__item--link.active {
  border-color: #FFFFFF;
}
@media (max-width: 849px) {
  .navigation__item--link.active {
    border: none;
    color: #000000;
    background: #FFFFFF;
  }
}
.navigation__btn {
  height: 2.8rem;
  padding: 0.8rem 0.5rem;
  width: 0;
  outline: none;
  background: transparent;
  border: none;
  position: absolute;
  opacity: 0.5;
}
@media (max-width: 849px) {
  .navigation__btn {
    opacity: 1;
    width: 4rem;
    right: 2.5rem;
    top: 2.5rem;
  }
}
.navigation__btn::after {
  content: "";
  position: absolute;
  display: none;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  height: 350%;
  width: 200%;
  z-index: -1;
  transform: translate(-25%, -25%);
  filter: blur(3px);
  border-radius: 10px;
}
.navigation__btn svg {
  stroke: #FFFFFF;
}

body, a, button, #map {
  cursor: none;
}
@media (max-width: 849px) {
  body, a, button, #map {
    cursor: unset;
  }
}

body.auto-cursor {
  cursor: unset;
}

.circle {
  position: fixed;
  background: transparent;
  border: 2px solid #FFFFFF;
  filter: blur(1px);
  width: 4rem;
  height: 4rem;
  left: -2rem;
  top: -2rem;
  border-radius: 100%;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  transform: scale(1);
}
@media (max-width: 849px) {
  .circle {
    display: none;
  }
}
.circle.active {
  opacity: 1;
  transform: scale(0);
}
.circle.on-vid {
  background: #000000;
  border: 2px solid transparent;
  filter: none;
}
.circle.on-vid .fullscreen-mask {
  opacity: 1;
}
.circle .fullscreen-mask {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 33%;
  width: 33%;
  overflow: hidden;
  opacity: 0;
  transition: all 300ms ease;
}
.circle .fullscreen-mask.active :not(:last-child) {
  transform: rotate(90deg);
}
.circle .fullscreen {
  background: #FFFFFF;
  position: absolute;
  transition: all 300ms ease;
}
.circle .fullscreen--x {
  width: 33%;
  height: 15%;
}
.circle .fullscreen--y {
  width: 15%;
  height: 33%;
}
.circle .fullscreen--static {
  width: 100%;
  height: 15%;
}
.circle .fullscreen--1 {
  left: 0;
  top: 0;
}
.circle .fullscreen--2 {
  left: 0;
  top: 0;
}
.circle .fullscreen--3 {
  right: 0;
  top: 0;
}
.circle .fullscreen--4 {
  right: 0;
  top: 0;
}
.circle .fullscreen--5 {
  right: 0;
  bottom: 0;
}
.circle .fullscreen--6 {
  right: 0;
  bottom: 0;
}
.circle .fullscreen--7 {
  left: 0;
  bottom: 0;
  transform: translateX(-25%) rotate(45deg);
}

.circle-follow {
  position: fixed;
  height: 7rem;
  width: 7rem;
  left: -3rem;
  top: -3rem;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.65);
  user-select: none;
  pointer-events: none;
  z-index: 1000;
  transform: scale(1);
  filter: blur(30px);
}
.circle-follow.active {
  transform: scale(3);
}

.expand {
  animation: cursorClick 500ms forwards;
}

.btn {
  position: relative;
  will-change: color;
  width: 22rem;
  height: 3.5rem;
  text-transform: uppercase;
  text-decoration: none;
  border: 1px solid #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  transition-property: color;
  transition-duration: 300ms;
  margin-top: 7.5rem;
}
@media (max-width: 649px) {
  .btn {
    width: 100%;
  }
}
.btn:hover {
  color: #000000;
}
.btn:hover:after {
  height: 100%;
}
.btn::after {
  will-change: height;
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #FFFFFF;
  transition-property: height;
  transition-duration: 300ms;
  z-index: -1;
}

.slot-container .slot {
  height: 100vh;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}
.slot-container .slot .roller {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.slot-container .slot .roller:not(:first-child) {
  opacity: 0;
  visibility: hidden;
}
.slot-container .slot__text-item:last-child p {
  width: 125%;
}
@media (max-width: 1799px) {
  .slot-container .slot__text-item:last-child p {
    width: auto;
  }
}
.slot-container .slot__text-item.active .slot__text--title {
  -webkit-text-fill-color: unset;
  -webkit-text-stroke-width: unset;
  -webkit-text-stroke-color: unset;
}
.slot-container .slot__container {
  grid-column: central-start/span 6;
  position: relative;
}
@media (max-width: 1799px) {
  .slot-container .slot__container {
    grid-column: central-start/span 7;
  }
}
@media (max-width: 1399px) {
  .slot-container .slot__container {
    grid-column: container-start/inside-container-end;
  }
}
@media (max-width: 849px) {
  .slot-container .slot__container {
    grid-column: full-start/full-end;
  }
}
.slot-container .slot__text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 99;
  max-width: 55vw;
}
@media (max-width: 1399px) {
  .slot-container .slot__text {
    max-width: 75vw;
  }
}
.slot-container .slot__text--title {
  width: 150%;
  font-size: 10rem;
  font-weight: normal;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #FFFFFF;
}
@media (max-width: 1599px) {
  .slot-container .slot__text--title {
    font-size: 8rem;
  }
}
@media (max-width: 1399px) {
  .slot-container .slot__text--title {
    font-size: 7rem;
  }
}
@media (max-width: 849px) {
  .slot-container .slot__text--title {
    font-size: 5rem;
    width: 100%;
  }
}
.slot-container .slot__text--description {
  height: 0;
  overflow: hidden;
}
.slot-container .slot__text--description.slot-initial {
  height: auto;
}
.slot-container .roller-vid {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.slot-container-industries .slot-industries {
  height: 100vh;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}
.slot-container-industries .slot-industries .roller-industries {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
}
.slot-container-industries .slot-industries .roller-industries:not(:first-child) {
  opacity: 0;
  visibility: hidden;
}
.slot-container-industries .slot-industries__text-item-industries.active .slot-industries__text-industries--title-industries {
  -webkit-text-fill-color: unset;
  -webkit-text-stroke-width: unset;
  -webkit-text-stroke-color: unset;
}
.slot-container-industries .slot-industries__container-industries {
  grid-column: center/container-end;
  position: relative;
}
@media (max-width: 1399px) {
  .slot-container-industries .slot-industries__container-industries {
    grid-column: 8/container-end;
  }
}
@media (max-width: 849px) {
  .slot-container-industries .slot-industries__container-industries {
    grid-column: full-start/full-end;
  }
}
.slot-container-industries .slot-industries__text-industries {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 99;
  max-width: 50vw;
}
.slot-container-industries .slot-industries__text-industries--title-industries {
  font-size: 10rem;
  font-weight: normal;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #FFFFFF;
}
@media (max-width: 1599px) {
  .slot-container-industries .slot-industries__text-industries--title-industries {
    font-size: 8rem;
  }
}
@media (max-width: 1399px) {
  .slot-container-industries .slot-industries__text-industries--title-industries {
    font-size: 7rem;
  }
}
@media (max-width: 849px) {
  .slot-container-industries .slot-industries__text-industries--title-industries {
    font-size: 5rem;
    max-width: 75vw;
  }
}
@media (max-width: 649px) {
  .slot-container-industries .slot-industries__text-industries {
    max-width: unset;
  }
}
.slot-container-industries .slot-industries__text-industries--description-industries {
  height: 0;
  overflow: hidden;
}
.slot-container-industries .slot-industries__text-industries--description-industries.slot-initial {
  height: auto;
}
.slot-container-industries .roller-vid-industries {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.slot-container-industries .roller-vid-industries.scaled {
  width: 90%;
}
@media (max-width: 849px) {
  .slot-container-industries .roller-vid-industries.scaled {
    width: 100%;
  }
}
.slot-container-industries #industries4.scaled {
  width: 90%;
}
@media (max-width: 849px) {
  .slot-container-industries #industries4.scaled {
    width: 950px;
  }
}