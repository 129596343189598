.navigation {
  grid-column: full-start / full-end;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  height: 5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 100;

  @include tablet {
    position: fixed;
    width: 100%;
    height: 0;
    z-index: 1001;
  }


  &__logo {
    position: absolute;
    left: 0;
    top: 18px;
    height: 2.5rem;
    width: 2.5rem;
    opacity: 1;
    transition: opacity 900ms ease;
    &.hide {
      opacity: 0;
    }

    @include tablet {
      left: 2.5rem;
      top: 2.5rem;
      z-index: 1;
    }
    @include mobile {
      left: calc(1.5rem + 16px);
    }

    &--link {
      position: relative;
      display: block;

      svg {
        height: 5rem;
        width: 3.5rem;
      }
    }
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
    max-width: 700px;
    width: 100%;
    height: 5rem;
    @include tablet {
      overflow: unset;
      height: 100vh;
      background: $color-primary;
      transform: translateY(-100%);
    }
  }

  &__list {
    min-width: 100%;
    position: absolute;
    height: 100%;
    width: 700px;
    right: 0;
    display: flex;
    align-items: center;
    list-style: none;
    @include tablet {
      background: #111;
      flex-direction: column;
      justify-content: space-around;
      width: auto;
      position: static;
      overflow: hidden;
      transform: translateY(-100%);
    }
  }

  &__item {
    padding: 1rem;
    @include tablet {
      width: 100%;
      height: calc(100vh / 8);
      text-align: center;
      border-bottom: 1px solid #333;
      padding: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &--link {
      font-size: 1.2rem;
      font-weight: 500;
      text-transform: uppercase;
      color: $color-secondary;
      text-decoration: none;
      letter-spacing: 1px;
      white-space: nowrap;
      border-bottom: 2px solid transparent;
      transition: all 300ms ease;
      will-change: border;
      @include tablet {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.active {
        border-color: $color-secondary;

        @include tablet {
          border: none;
          color: $color-primary;
          background: $color-secondary;
        }
      }
    }
  }

  &__btn {
    height: 2.8rem;
    padding: 0.8rem 0.5rem;
    width: 0;
    outline: none;
    background: transparent;
    border: none;
    position: absolute;
    opacity: .5;
    @include tablet {
      opacity: 1;
      width: 4rem;
      right: 2.5rem;
      top: 2.5rem;
    }

    &::after {
      content: "";
      position: absolute;
      display: none;
      background: rgba($color-primary, .7);
      top: 0;
      left: 0;
      height: 350%;
      width: 200%;
      z-index: -1;
      transform: translate(-25%, -25%);
      filter: blur(3px);
      border-radius: 10px;
    }



    svg {
      stroke: $color-secondary;
    }
  }
}