*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-x: hidden;
  @include lg-desktop {
    font-size: 57.5%;
  }
  @include tablet {
    font-size: 55%;
  }
}

body {
  position: relative;
  background: $color-primary;

  @include desktop {
    //overflow-y: hidden;
  }
}

.fullscreen-bg {
  position: fixed;
  height: 100vh;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  @include tablet {
    height: 90vh;
  }
  .background {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    @include tablet {
      -webkit-transform: translate(-50%, -55%);
      -moz-transform: translate(-50%, -55%);
      -ms-transform: translate(-50%, -55%);
      transform: translate(-50%, -55%);
    }
    &--loop {
      z-index: -2;
    }

    @include tablet {
      height: auto;
      width: 100%;
    }
  }
}



#map {
  height: 100%;
  min-height: 50vh;
}