// Header
header {
  height: calc(100vh - 5rem);
  grid-column: container-start / container-end;
  @include tablet {
    grid-column: full-start / full-end;
  }
}

.about {
  grid-column: container-start / inside-container-end;
  display: flex;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: rgba($color-primary, .3);
    filter: blur(20px);
    z-index: -1;
    opacity: 0;
    transition: opacity 300ms ease;
  }
  &.active {
    &::after {
      opacity: 1;
    }
  }
  @include tablet {
    grid-column: full-start / full-end;
    margin-top: 15rem;
  }


  p {
    &:not(.lead) {
      max-width: 50%;
      @include sm-desktop {
        max-width: unset;
      }
    }
  }
}

.feature-movie {
  grid-column: central-start / central-end;
  height: 300vh;
  position: relative;
  &.safari {
    height: 125vh;
    @include tablet {
      height: auto;
    }
  }
  @include desktop {
    grid-column: 5/span 8;
  }
  @include tablet {
    min-height: 33vh;
    grid-column: full-start / full-end;
    height: auto;
  }

  &__content {
    top: 50%;
    margin-top: 50%;
    transform: translateY(-50%);
    //left: 0;
    position: sticky;
    position: -webkit-sticky;
    width: 100%;
    @include tablet {
      margin-top: 0;
      top: auto;
      transform: translateY(0);
      position: relative;
      height: 80vh;
      object-fit: cover;
    }
    &:-webkit-full-screen {
      position: static!important;
      transform: none!important;
      margin-top: 0!important;
      cursor: default!important;
    }
  }
}

// Selected projects section
.selected {
  grid-column: 1 / -1;
  position: relative;
  //overflow: hidden;
  @include tablet {
    grid-column: full-start / -1;
    overflow: hidden;
  }

  .swiper {
    overflow: unset;



    .swiper-slide-active {
      .selected__item--title {
        opacity: 1;
        transition: opacity 300ms ease;
      }

      .selected__item--info {
        opacity: 1;
        transition: opacity 300ms ease;
      }

      .selected__item--more-info {
        opacity: 1;
        transition: opacity 300ms ease;
      }
    }
  }

  &__title {
    //grid-column: container-start / container-end;
    //position: relative;
    //height: 2.5rem;
    position: absolute;

    h2 {
      position: sticky;
      position: -webkit-sticky;
      //bottom: -400%;
    }
  }

  &__container {
    grid-column: container-start / -1;
    @include tablet {
      grid-column: 1 / -1;
    }
    //width: 600%;
    display: flex;
    padding-top: 10vh;
  }

  &__item {
    //width: 75vw;
    height: 65vh;
    min-height: 600px;
    margin-top: 10vh;
    margin-bottom: 10vh;

    display: grid;
    grid-template-columns: 20% 20% auto;
    grid-template-rows: 50vh auto;
    grid-gap: 5rem;

    @include tablet {
      grid-template-rows: 40vh 2rem auto;
      grid-gap: 1rem;
      height: 65vh;
      margin-top: 10vh;
      min-height: unset;
    }
    @include mobile {
      grid-template-rows: 15vh 25vh 25vh;
      grid-template-columns: 40% 40% 20%;
    }
    @include sm-mobile {
      grid-template-rows: 10vh 20vh 40vh;
    }



    p {
      font-size: 1.4rem;
      font-weight: normal;
      line-height: 18px;
    }

    &--wrapper {
      grid-column: 1 / -1;
      max-height: 50vh;
      margin-right: 5vw;
      position: relative;

      @include mobile {
        grid-column: 2 / -1;
        grid-row: 1 / -1;
        max-height: unset;
      }
    }

    &--title {
      grid-column: 1;
      transition: opacity 300ms ease;
      opacity: 0;
      font-size: 1.9rem;
      font-weight: 700;
      @include tablet {
        grid-column: 1 / -1;
        opacity: 1;
      }
      @include mobile {
        grid-column: 1;
        grid-row: 1;
        font-size: 1.6rem;
      }
    }

    &--info {
      grid-column: 2;

      transition: opacity 300ms ease;
      opacity: 0;

      @include tablet {
        grid-column: 1;
        opacity: 1;
      }
      @include mobile {
        grid-column: 1;
        grid-row: 2;
      }
    }

    &--more-info {
      grid-column: 3;

      transition: opacity 300ms ease;
      opacity: 0;
      @include tablet {
        grid-column: 2;
        opacity: 1;
      }
      @include mobile {
        grid-column: 1;
        grid-row: 3;
      }
    }

    &--media {
      width: 100%;
      object-fit: cover;
      height: 100%;
      @include tablet {
        object-fit: cover;
      }
      @include mobile {
        object-fit: cover;
        height: 95%;
      }
      &:-webkit-full-screen {
        width: 100%;
        height: 100%;
        
      }
    }

    //&:not(:last-child) {
    //  margin-right: 5vw;
    //}

    &--play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: $color-secondary;
      opacity: .8;
      @include tablet {
        width: 50%;
        max-width: 75px;
      }
      @include mobile {
        max-width: 40px;
      }
    }
  }
}

// Logo
.logo {
  stroke: $color-secondary;
  fill: $color-secondary;
}

// Icons
.burger-icon {
  width: 100%;
  height: auto;
  max-height: 100%;
}

// Fixed bar
.fixed-bar {
  grid-column: full-start / full-end;
  display: flex;
  justify-content: space-between;
}

// Fixed social icons
.social-icons {
  position: fixed;
  bottom: 4.5rem;
  display: flex;
  flex-direction: column;
  z-index: 100;
  @include mobile {
    display: none;
  }

  &__icon {
    display: block;
    height: 3rem;
    width: 3rem;




    &:not(:last-child) {
      margin-bottom: 2rem;
      @include tablet {
        margin-bottom: 1.5rem;
      }
    }

    &--media {
      fill: $color-secondary;
      width: 100%;
      height: 100%;
      transition: all 300ms ease;
      &:hover {
        fill: #444;
      }
    }
  }
}

// Vertical text
.vertical {
  position: fixed;
  bottom: 4.5rem;
  right: 0;
  width: 30rem;
  z-index: 100;
  transform: rotate(90deg) translateX(-135px) translateY(-100px);
  @include mobile {
    display: none;
  }
}

.section-lead {
  grid-column: container-start / span 7;
  height: 60vh;
  padding-top: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include tablet {
    grid-column: full-start / full-end;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
  @include mobile {
    padding-top: 0;
    height: auto;
    padding-bottom: 10rem;
  }
}

// Services
.services, .industries {
  grid-column: 1 / -1;
  min-height: 100vh;
}

// Talents section
.talents, .facilities {
  //height: 60vh;
  padding-top: 20vh;
  grid-column: central-start / central-end;

  @include desktop {
    grid-column: container-start / container-end;
  }
  @include tablet {
    grid-column: full-start / full-end;
  }

  &__content {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 4rem 11rem;
    min-height: 65rem;
    @include tablet {
      padding: 2rem 4rem;
      height: auto;
    }

    h2, p {
      z-index: 1;
    }
  }
}

.talents__content {
  background-image: url("../assets/img/ThePack_GroupPicture_1756_edit_v002_min.jpg");
  position: relative;
  background-position: bottom;


  &::after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    background: $color-primary;
    opacity: 0;
    transition: opacity 300ms ease;
  }

  &.active {
    &::after {
      opacity: .4;
    }
  }
}

.facilities {
  position: relative;
  &__mask {
    grid-column: 1/-1;
    overflow: hidden;
    padding-bottom: 30rem;
    @include mobile {
      padding-bottom: 60rem;
    }
  }
  &__text {
    position: absolute;
    top: 75%;
    h2.heading__secondary {
      margin-bottom: 20rem;
    }
  }
  .ticker-wrapper{
    position: absolute;
    bottom: 0;
  }
  &__animated-text {
    display: flex;
    position: absolute;
    left: 0;
    z-index: 2;
    bottom: 60%;
    overflow: hidden;
    animation: ticker 180s linear infinite;

    p {
      width: 100%;
      white-space: nowrap;
      font-weight: 300;
      font-size: 5.6rem;
      margin-left: 10rem;
      position: relative;
      @include md-desktop {
        font-size: 5rem;
      }
      @include desktop {
        font-size: 4rem;
      }
      @include tablet {
        font-size: 2.5rem;
      }
      &:after {
        content: "⤑";
        position: absolute;
        height: 100%;
        right: -7.5rem;
        width: 5rem;
      }
    }
  }

  &__content {
    position: relative;
    background-image: url("../assets/img/ThePack_lnt1_GradingRoom_1683_min.jpg");
    @include tablet {
      min-height: 20rem;
    }
  }
}


// Contact Section
// Contact Button
.cta {
  height: 45vh;
  grid-column: container-start / span 8;
  padding-top: 20vh;
  @include desktop {
    min-height: 50rem;
  }
  @include tablet {
    grid-column: full-start / full-end;
    padding-top: 5rem;
    height: auto;
  }
}

// Contact Data
.contact {
  height: 45vh;
  grid-column: inside-container-start / inside-container-end;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 5vh;
  @include tablet {
    grid-column: full-start / full-end;
  }
  @include mobile {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  &__box {
    width: 33%;
    max-width: 22rem;
    position: relative;
    margin-bottom: 5rem;
    @include mobile {
      width: 100%;
      max-width: 300px;
      //display: flex;
      //flex-direction: column;
      //justify-content: center;
      //align-items: center;
      margin-top: 2.5rem;
      margin-bottom: 0;

      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 50px auto;
    }
    h4 {
      @include mobile {
        grid-row: 1;
        grid-column: 1;
        margin: 0;
      }
    }
    address {
      @include mobile {
        grid-row: 2;
        grid-column: 1;
      }
    }
    button {
      @include mobile {
        grid-row: 3;
        grid-column: 1;
        align-items: center;
      }
    }
  }

  &__btn {
    outline: none;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile {
      justify-content: flex-start;
      align-items: flex-start;
    }

    span {
      font-size: 1.4rem;
    }
  }

  &__icon {
    height: 2rem;
    width: 2rem;
    margin-right: 1rem;
    fill: $color-secondary;
  }
}

// Map Styles
footer {
  grid-column: 1 / -1;
}


.reveal {
  overflow: hidden;
  height: 100%;
  min-height: 250px;
}

.ios-iphone-bg {
  width: 100%;
  margin: auto;
  height: auto;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.lb {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba($color-primary, .66);
  z-index: 1000;
  &.ios {
    z-index: unset;
    background: none;
  }

  &__img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    height: auto;
    object-fit: cover;
  }
}


.swiper {
  width: 100%;
  height: 100%;
  grid-column: 1 / -1;
  min-height: 20rem;
}

.swiper-slide {
  display: block;
  width: 80%;
  @include mobile {
    width: 85%;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-pagination-bullet {
  width: 2rem;
  height: 2rem;
  background:rgba($color-secondary, .6);
}

.swiper-pagination-bullet-active {
  background: rgba($color-secondary, .8);
}

.anchor {
  display: block;
  height: 155px;
  margin-top: -155px;
  visibility: hidden;
}

.selected {
  .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: none;
  }
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0;
  }
}

.feature-btn-play {
  display: none;
  @include mobile {
    display: block;
    max-width: 100px;
  }
}
