body, a, button, #map {
  cursor: none;
  @include tablet {
    cursor: unset;
  }
}

body.auto-cursor {
  cursor: unset;
}

.circle {
  position: fixed;
  background: transparent;
  border: 2px solid $color-secondary;
  filter: blur(1px);
  width: 4rem;
  height: 4rem;
  left: -2rem;
  top: -2rem;
  border-radius: 100%;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  transform: scale(1);
  @include tablet {
    display: none;
  }

  &.active {
    opacity: 1;
    transform: scale(0);
  }

  &.on-vid {
    background: $color-primary;
    border: 2px solid transparent;
    filter: none;

    .fullscreen-mask {
      opacity: 1;
    }
  }

  .fullscreen-mask {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 33%;
    width: 33%;
    overflow: hidden;
    opacity: 0;
    transition: all 300ms ease;
    &.active {

      & :not(:last-child) {
        transform: rotate(90deg);
      }
    }
  }

  .fullscreen {
    background: $color-secondary;
    position: absolute;
    transition: all 300ms ease;

    &--x {
      width: 33%;
      height: 15%;
    }

    &--y {
      width: 15%;
      height: 33%;
    }

    &--static {
      width: 100%;
      height: 15%;
    }

    &--1 {
      left: 0;
      top: 0;
    }
    &--2 {
      left: 0;
      top: 0;
    }
    &--3 {
      right: 0;
      top: 0;
    }
    &--4 {
      right: 0;
      top: 0;
    }
    &--5 {
      right: 0;
      bottom: 0;
    }
    &--6 {
      right: 0;
      bottom: 0;
    }

    &--7 {
      left: 0;
      bottom: 0;
      transform: translateX(-25%) rotate(45deg);
    }
  }
}

.circle-follow {
  position: fixed;
  height: 7rem;
  width: 7rem;
  left: -3rem;
  top: -3rem;
  border-radius: 100%;
  background: rgba($color-secondary, .65);
  user-select: none;
  pointer-events: none;
  z-index: 1000;
  //overflow: hidden;
  transform: scale(1);
  filter: blur(30px);

  &.active {
    transform: scale(3);
  }

}

.expand {
  animation: cursorClick 500ms forwards;
  //height: 1rem;
  //width: 1rem;
  //background: $color-primary;
  //border-radius: 50%;
}
