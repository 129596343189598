.btn {
  position: relative;
  will-change: color;
  width: 22rem;
  height: 3.5rem;
  text-transform: uppercase;
  text-decoration: none;
  border: 1px solid $color-secondary;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  transition-property: color;
  transition-duration: 300ms;
  margin-top: 7.5rem;
  @include mobile {
    width: 100%;
  }

  &:hover {
    color: $color-primary
  }
  &:hover:after {
    height: 100%;
  }

  &::after {
    will-change: height;
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: $color-secondary;
    transition-property: height;
    transition-duration: 300ms;
    z-index: -1;
  }

}