.slot-container {
  .slot {
    height: 100vh;
    position: sticky;
    position: -webkit-sticky;
    top: 0;

    .roller {
      position: absolute;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;

      &:not(:first-child) {
        opacity: 0;
        visibility: hidden;
      }
    }
    &__text-item {
      &:last-child {
        p {
          width: 125%;
          @include lg-desktop {
            width: auto;
          }
        }
      }

      &.active {
        .slot__text--title {
          -webkit-text-fill-color: unset;
          -webkit-text-stroke-width: unset;
          -webkit-text-stroke-color: unset;
        }
        //.slot__text--description {
        //  height: auto;
        //}
      }
    }

    &__container {
      grid-column: central-start / span 6;
      position: relative;
      @include lg-desktop {
        grid-column: central-start/ span 7;
      }

      @include desktop {
        grid-column: container-start / inside-container-end;
      }
      @include tablet {
        grid-column: full-start / full-end;
      }
    }

    &__text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      z-index: 99;
      max-width: 55vw;
      @include desktop {
        max-width: 75vw;
      }



      &--title {
        width: 150%;
        font-size: 10rem;
        font-weight: normal;
        -webkit-text-fill-color: rgba($color-secondary, 0);
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $color-secondary;

        @include md-desktop {
          font-size: 8rem;
        }
        @include desktop {
          font-size: 7rem;
        }
        @include tablet {
          font-size: 5rem;
          width: 100%;
        }
      }

      &--description {
        height: 0;
        overflow: hidden;
        &.slot-initial {
          height: auto;
        }
      }
    }
  }

  .roller-vid {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.slot-container-industries {
  .slot-industries {
    height: 100vh;
    position: sticky;
    position: -webkit-sticky;
    top: 0;

    .roller-industries {
      position: absolute;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      overflow: hidden;
      &:not(:first-child) {
        opacity: 0;
        visibility: hidden;
      }
    }

    &__text-item-industries {

      &.active {
        .slot-industries__text-industries--title-industries {
          -webkit-text-fill-color: unset;
          -webkit-text-stroke-width: unset;
          -webkit-text-stroke-color: unset;
        }
      }
    }

    &__container-industries {
      grid-column: center / container-end;
      position: relative;
      @include desktop {
        grid-column: 8 / container-end;
      }

      @include tablet {
        grid-column: full-start / full-end;
      }
    }

    &__text-industries {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      z-index: 99;
      max-width: 50vw;

      &--title-industries {
        font-size: 10rem;
        font-weight: normal;
        -webkit-text-fill-color: rgba($color-secondary, 0);
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $color-secondary;

        @include md-desktop {
          font-size: 8rem;
        }
        @include desktop {
          font-size: 7rem;
        }
        @include tablet {
          font-size: 5rem;
          max-width: 75vw;
        }


      }
      @include mobile {
        max-width: unset;
      }

      &--description-industries {
        height: 0;
        overflow: hidden;
        &.slot-initial {
          height: auto;
        }
      }
    }
  }

  .roller-vid-industries {
    height: 100%;
    width: 100%;
    object-fit: cover;
    &.scaled {
      width: 90%;
      @include tablet {
        width: 100%;
        
      }
    }
  }
  #industries4{
    &.scaled {
      width: 90%;
      @include tablet {
        width: 950px;
        
      }
    }
  }
}
