.grid-layout {
  display: grid;
  grid-gap: 0 16px;
  grid-template-columns: 3rem [full-start] minmax(3rem, 1fr) [container-start] 10.2rem [central-start] 10.2rem [inside-container-start] repeat(4, 10.2rem) [center] repeat(4, 10.2rem) [inside-container-end] 10.2rem [central-end] 10.2rem  [container-end] minmax(3rem, 1fr)  [full-end] 3rem;

  @include desktop {
    grid-template-columns: 1.5rem [full-start] minmax(1.5rem, 1fr) [container-start] repeat(2, 5rem) [inside-container-start] repeat(4, 10.2rem) [center] repeat(4, 10.2rem) [inside-container-end] repeat(2, 5rem) [container-end] minmax(1.5rem, 1fr)  [full-end] 1.5rem;
  }

  @include tablet {
    grid-template-columns: 1.5rem [full-start] minmax(1.5rem,1fr) [container-start]  1fr [center] 1fr [container-end] minmax(1.5rem,1fr) [full-end] 1.5rem;
  }
}

