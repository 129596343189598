* {
  color: $color-secondary;
}
body {
  font-family: 'HalvarMittel', "Calibri", sans-serif;
}

.heading__primary {
  text-transform: uppercase;
  margin-top: 65vh;
  @include desktop {
    margin-top: 50vh;
  }
  @include mobile {
    margin-top: 70vh;
  }
  .mask {
    &:first-of-type {
      height: 5rem;
    }
    position: relative;
    overflow: hidden;
    height: 14rem;
    @include tablet {
      height: 8rem;
    }
  }

  &--top {
    font-size: 5.6rem;
    line-height: 1;
    font-weight: 300;
    @include tablet {
      font-size: 4.6rem;
    }
    @include mobile {
      font-size: 3.6rem;
    }
  }

  &--bottom {
    font-size: 14rem;
    line-height: 1;
    font-weight: 500;
    @include tablet {
      font-size: 9rem;
    }
    @include mobile {
      font-size: 7rem;
    }
  }

  span {
    display: block;
  }
}

.heading__secondary {
  font-size: 2.6rem;
  font-weight: 300;
  margin-bottom: 2.6rem;
  line-height: 31px;
  text-transform: uppercase;
}

.reveal-text {
  position: absolute;
  z-index: 200;
  top: 100%;
}
p {
  &.lead {
    margin-bottom: 2.5rem;
    font-size: 3.6rem;
    font-weight: 700;
    @include tablet {
      font-size: 3rem;
    }
    @include mobile {
      font-size: 2.5rem;
    }
    i {
      font-weight: normal;
      font-style: oblique;
    }
  }
  &:not(.lead) {
    font-size: 1.8rem;
    font-weight: normal;
  }
}
.about {
  p {
    &:not(.lead) {
      padding-left: 4.5rem;
      &:not(:last-child) {
        margin-bottom: 2.5rem;
      }
    }
  }
}


input {
  font-family: 'Montserrat', sans-serif;
}

.text-box {
  position: relative;

  h3 {
    font-size: 3rem;
    font-weight: 600;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  > :not(:last-child) {
    margin-bottom: 2rem;
  }
}

.numbers {
  .text-box {
    h3 {
      font-weight: 500;
      font-size: 10rem;
      @include tablet {
        font-size: 6rem;
      }

      small {
        font-size: 6rem;
        @include tablet {
          font-size: 3rem;
        }
      }
    }
  }
}

.contact {

  h4 {
    font-size: 3.6rem;
    margin: 3rem 0;
    font-style: normal;
  }
  p {
    font-size: 2rem;
    font-style: normal;
  }

  a {
    display: block;
    font-size: 3rem;
    text-decoration: none;
  }

  address {

    p {
      font-size: 1.4rem;
    }
    a {
      font-size: inherit;
    }
  }
}
