@mixin lg-desktop {
  @media (max-width: 1799px) {
    @content;
  }
}
@mixin md-desktop {
  @media (max-width: 1599px) {
    @content;
  }
}
@mixin desktop {
  @media (max-width: 1399px) {
    @content;
  }
}
@mixin sm-desktop {
  @media (max-width: 999px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: 849px) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: 649px) {
    @content;
  }
}@mixin sm-mobile {
  @media (max-width: 376px) {
    @content;
  }
}